import React from "react";

import {
	Table,
} from "semantic-ui-react";
import Answer from "./Answer";

const QuestionTable = (props) => {
	//spalten sind die fragen (sortiert nach )
	let cols = {};
	const rows = {};
	for (let answer of props.answers) {
		if (!(answer._question.id in cols)) {
			cols[answer._question.id] = {
				title: answer._question.title,
				ordering: answer._question.ordering,
				id: answer._question.id,
			};
		}
		if (!(answer._resource.id in rows)) {
			rows[answer._resource.id] = {
				title: answer._resource.fullName,
				cols: {},
			};
		}
		rows[answer._resource.id].cols[answer._question.id] = answer;
	}

	cols = Object.values(cols).sort((a, b) =>
		a.ordering < b.ordering ? -1 : a.ordering > b.ordering ? 1 : 0
	);

	let rowsList = Object.values(rows).sort((a, b) => a.title.localeCompare(b.title));
	let removeConditionList = ['Fitter / Driver Timesheet', 'Pave Timesheet'];

	return (cols.length >= rowsList.length && !removeConditionList.includes(props.name)) ? (
		<Table celled definition className="table-questionary">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell className="qDefinitionTitle reverse">{props.title}</Table.HeaderCell>
					{rowsList.map((x) => (
						<Table.HeaderCell>{x.title}</Table.HeaderCell>
					))}
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{cols.map((x) => (
					<Table.Row>
						<Table.Cell className="qColName table-questionary-cell">{x.title}</Table.Cell>
						{rowsList.map((y) => (
							<Table.Cell>{y.cols[x.id] ? <Answer answer={y.cols[x.id]} /> : null}</Table.Cell>
						))}
					</Table.Row>
				))}

			</Table.Body>
		</Table>
	)
	:(
		<Table celled definition className="table-questionary">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell className="qDefinitionTitle proper">{props.title}</Table.HeaderCell>
					{cols.map((x) => (
						<Table.HeaderCell>{x.title}</Table.HeaderCell>
					))}
				</Table.Row>
			</Table.Header>

			<Table.Body>
				{Object.values(rows)
					.sort((a, b) => a.title.localeCompare(b.title))
					.map((x) => (
						<Table.Row>
							<Table.Cell className="qColName table-questionary-cell">{x.title}</Table.Cell>
							{/* <Table.Cell className="qColName" collapsing>{x.title}</Table.Cell> */}
							{cols.map((y) => (
								<Table.Cell>{x.cols[y.id] ? <Answer answer={x.cols[y.id]} /> : null}</Table.Cell>
							))}
						</Table.Row>
					))}
			</Table.Body>
		</Table>
	);
};

export default QuestionTable;
